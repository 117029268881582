.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: .5rem;
}

.delta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0 1 4rem;

  border: 1px solid lightgray;
  border-radius: .5rem;
  padding: 0 .5rem;

  & > svg {
    width: 1rem;
  }

  &_up {
    fill: var(--color-primary-1);
  }

  &_down {
    fill: var(--color-primary-4);
  }

  &_none {
    fill: var(--color-primary-7);
  }
}
