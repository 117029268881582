.year {
  font-family: 'Ubuntu', sans-serif;
  font-size: 2.8rem;
  font-weight: 600;
  color: var(--color-primary-1);
}

.infoDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  & > p {
    text-align: center;
  }
}
