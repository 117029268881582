@use './local.module';

.container {
  @include local.container;

  color: var(--color-black-1);
  background: var(--color-primary-1);
  border: 2px solid var(--color-primary-1);
  border-radius: 1rem;

  & svg {
    fill: var(--color-black-1);
  }

  &:hover {
    background: var(--color-primary-2);
    border: 2px solid var(--color-primary-2);
  }

  &:focus-visible {
    outline: none;

    border: 2px solid var(--border-focus);
  }

  &:active {
    background: var(--color-primary-3);
    border: 2px solid var(--color-primary-3);
  }
}
