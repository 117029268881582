.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem 1.5rem;

  height: fit-content;

  border: 1px solid var(--color-primary-3);
  border-radius: .5rem;
}

.header {
  display: flex;
  align-items: center;
  gap: 1rem;

  &:hover {
    cursor: pointer;
  }

  & > svg {
    width: 1rem;
    height: 1rem;
  }
}

.inner {
  display: flex;
  gap: 2rem;

  height: fit-content;

  border-radius: .5rem;

  & > div {
    flex: 1 30%;
  }
}

.optionsList {
  overflow-y: auto;

  height: 13rem;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}
