@use './local.module';

.container {
  @include local.container;

  color: var(--color-primary-1);
  border: 1px solid transparent;
  border-radius: 1rem;

  & svg {
    fill: var(--color-primary-1);
  }

  &:hover {
    cursor: pointer;

    color: var(--color-primary-2);
    border: 1px solid transparent;
  }

  &:focus-visible {
    outline: var(--border-focus) solid 1px;

    border: 1px solid var(--border-focus);
  }

  &:active {
    color: var(--color-primary-3);
    border: 1px solid transparent;
  }
}
