@font-face {
  font-family: 'Arial';
  src: url(./fonts/ArialMT.ttf) format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  src: url(./fonts/Ubuntu-Regular.ttf) format('truetype');
  font-weight: 400
}

:root {
  --color-primary-1: #00356B;
  --color-primary-2: #004385;
  --color-primary-3: #406eb9;
  --color-primary-4: #81a0dc;
  --color-primary-5: #a4bdee;
  --color-primary-6: #D0DEF5;
  --color-primary-7: #f1f7ff;

  --color-black-1: #FAFAFA;
  --color-black-2: #E0E0E0;
  --color-black-3: #BDBDBD;
  --color-black-4: #252525;
  --color-black-5: #171717;

  --box-shadow-1: 0 0 10px 0 rgba(24, 48, 84, 0.2);
  --box-shadow-2: 0 0 25px 0 rgba(24, 48, 84, 0.2);
  --box-shadow-3: 0 0 35px 0 rgba(24, 48, 84, 0.2);
}

h1, h2, h3, h4 {
  font-family: 'Ubuntu', sans-serif;
}
