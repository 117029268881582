//@use 'src/core/_styles/variables/typography';

$icon-margin: 1rem;

@mixin container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  padding: .5rem 1rem;

  font-weight: 400;
  text-decoration: none;

  &:has(svg) {
    padding: .5rem 0.63rem;
  }

  & > svg {
    @include icon
  }
}

@mixin icon {
  display: flex;
  align-items: center;

  min-height: 1.26rem;
  height: 1.26rem;
  min-width: 1rem;
  width: 1rem;
}

.leftIcon {
  margin-right: $icon-margin;

  & > svg {
    @include icon;
  }
}

.rightIcon {
  margin-left: $icon-margin;

  & > svg {
    @include icon;
  }
}
