.container {
  display: inline;
}

.tooltip {
  width: fit-content;
  background-color: #444;
  color: white;
  font-size: 90%;
  padding: .25rem .5rem;
  border-radius: .25rem;
}
