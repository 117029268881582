.container {
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 4rem;

  background-color: var(--color-black-1);

  box-shadow: var(--box-shadow-3);
}

.links {
  display: flex;
  gap: 1vw;
}

.text {
  color: var(--color-black-4);
  font-size: .8rem;
}
