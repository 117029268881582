.container {
  width: fit-content;

  & > svg {
    height: 1.5rem;
    width: auto;
  }

  &_dark {
    & > svg {
      fill:  var(--color-primary-1);
    }
  }

  &_light {
    & > svg {
      fill:  var(--color-primary-7);
    }
  }
}
